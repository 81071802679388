import WasmController from "react-lib/frameworks/WasmController";

import axios, { AxiosError } from "axios";

// APIs
// INF
import BillTransactionIPDSummary from "issara-sdk/apis/BillTransactionIPDSummary_apps_INF";
import BillTransactionSummaryList from "issara-sdk/apis/BillTransactionSummaryList_apps_INF";
import GenerateSentClaimFileFromSentClaimTransactionList from "issara-sdk/apis/GenerateSentClaimFileFromSentClaimTransactionList_apps_INF";
import SentClaimTransactionIPDList from "issara-sdk/apis/SentClaimTransactionIPDList_apps_INF";
import SentEClaimTransactionList from "issara-sdk/apis/SentEClaimTransactionList_apps_INF";

// Interface
import {
  SetErrorMessage,
  SetProperty,
  downloadZipFile,
} from "react-lib/apps/HISV3/common/CommonInterface";

import { State as MainState } from "HIS/MainHISInterface";

// Utils
import { downloadFile } from "react-lib/utils/utils";

export type State = Partial<{
  // sequence
  SendClaimDownloadZipFileSequence: Partial<{
    sequenceIndex: "Action" | "START" | null;
    selectedTransaction: Record<string, any> | null;
    transactionList: Record<string, any>[];
    transactionSummary: SentClaimTransactionSummary | null;
  }> | null;
}>;

type PickedState = Partial<Pick<MainState, "buttonLoadCheck" | "errorMessage">>;

export type PickedProps = Partial<Omit<PickedState, "">>;

export type SendClaimType = "E-CLAIM" | "IPD";

export type SentClaimTransactionSummary = Partial<{
  total_other_pay: number | string;
  total_other_pay_price: number | string;
  total_paid: number | string;
  total_paid_price: number | string;
  total_rows: number | string;
  total_sent_claim_price: number | string;
}>;

// Sequence
type SeqState = {
  sequence: "SendClaimDownloadZipFile";
  arTransaction?: number;
  clear?: boolean;
  restart?: boolean;
  type?: SendClaimType;
};

// Handle Action
type ActionType =
  // Action
  | {
      action: "SEND_CLAIM_SUMMARY";
      card: string;
      data: Record<string, any>;
      type: SendClaimType;
    }
  | { action: "DOWNLOAD_ZIP_FILE"; card: string; data: Record<string, any>; type: SendClaimType }
  | { action: "SEARCH"; arTransaction?: number; type: SendClaimType };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => void;

export type SetProp = SetProperty<PickedState & State>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  SendClaimDownloadZipFileSequence: {
    sequenceIndex: null,
  },
};

export type Event = { message: "RunSequence"; params: { sequence: string; [key: string]: any } };

export type Data = {
  device?: number;
  division?: number;
};

export const DataInitial = {};

export const MOD_DOWNLOAD_ZIP_FILE = "ModDownloadZipFile";

type Optional<T> = {
  [K in keyof T]: `${typeof MOD_DOWNLOAD_ZIP_FILE}_${T[K] & string}`;
};

export const ACTIONS = {
  INIT: "INIT",
  DOWNLOAD_ZIP_FILE: "DOWNLOAD_ZIP_FILE",
  SEARCH: "SEARCH",
  SEND_CLAIM_SUMMARY: "SEND_CLAIM_SUMMARY",
} as const;

export const BTN_ACTS = Object.fromEntries(
  Object.keys(ACTIONS).map((key) => [key, `${MOD_DOWNLOAD_ZIP_FILE}_${key}`])
) as Optional<typeof ACTIONS>;

type Handler<P = any, R = any> = (
  controller: WasmController<PickedState & State, Event, Data>,
  params: P
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller, params) => {
  const state = controller.getState();

  controller.setState(
    {
      SendClaimDownloadZipFileSequence: {
        ...state.SendClaimDownloadZipFileSequence,
        sequenceIndex: "Action",
      },
    },
    () => {
      if (params.type) {
        Action(controller, { ...params, action: "SEARCH", type: params.type });
      }
    }
  );
};

export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.DOWNLOAD_ZIP_FILE]: HandleDownloadZipFileSendClaim,
    [ACTIONS.SEARCH]: HandleSearchSendClaim,
    [ACTIONS.SEND_CLAIM_SUMMARY]: HandleSendClaimSummary,
  };

  const { action } = params;

  return actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

/* ------------------------------------------------------ */

/*                         Action                         */

/* ------------------------------------------------------ */
const HandleSearchSendClaim: Handler<Params<"SEARCH">> = async (controller, params) => {
  const state = controller.getState();

  const api = {
    "E-CLAIM": SentEClaimTransactionList.list,
    IPD: SentClaimTransactionIPDList.list,
  }[params.type];

  const [result] = await api({
    apiToken: controller.apiToken,
    params: { ar_transaction: params.arTransaction },
  });

  controller.setState({
    SendClaimDownloadZipFileSequence: {
      ...state.SendClaimDownloadZipFileSequence,
      transactionList: result?.items || [],
    },
  });
};

const HandleSendClaimSummary: Handler<Params<"SEND_CLAIM_SUMMARY">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEND_CLAIM_SUMMARY]: "LOADING" },
  });

  const { data } = params;

  const apis = {
    "E-CLAIM": {
      data: { sent_e_claim_transaction: data.id },
      get: BillTransactionSummaryList.get,
    },
    IPD: {
      data: { sent_claim_transaction_ipd: data.id },
      get: BillTransactionIPDSummary.list,
    },
  }[params.type];

  const [result, error] = await apis.get({
    apiToken: controller.apiToken,
    params: {
      ar_transaction: data.ar_transaction,
      ...apis.data,
    },
  });

  const errMsg = error || result?.items?.error;

  if (errMsg) {
    SetErrorMessage(controller, { ...params, error: errMsg });

    return;
  }

  controller.setState({
    SendClaimDownloadZipFileSequence: {
      ...state.SendClaimDownloadZipFileSequence,
      selectedTransaction: data,
      transactionSummary: result?.items || result || {},
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEND_CLAIM_SUMMARY]: "SUCCESS" },
  });
};

const HandleDownloadZipFileSendClaim: Handler<Params<"DOWNLOAD_ZIP_FILE">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DOWNLOAD_ZIP_FILE]: "LOADING" },
  });

  await(
    params.type === "IPD"
      ? DownSendClaimFile(controller, {
          sentFile: params.data.sent_claim_zip_file,
          zipFileName: params.data.zip_file_name,
        })
      : DownSendClaimFile(controller, {
          sentFile: params.data.sent_file,
          zipFileName: params.data.zip_file_name,
        })
  );

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.DOWNLOAD_ZIP_FILE]: "SUCCESS" },
  });
};

// const DownloadSendClaimFile: Handler<Params<"DOWNLOAD_ZIP_FILE">> = async (controller, params) => {
//   const [, error, net] = await axios.get({
//     apiToken: controller.apiToken,
//     sent_claim_transaction_id: params.data.id,
//     extra: { responseType: "blob" },
//   });

//   if (error) {
//     SetErrorMessage(controller, { ...params, error });

//     return;
//   }

//   downloadFile(net);
// };

const DownSendClaimFile: Handler<{ sentFile: string; zipFileName: string }> = async (
  controller,
  params
) => {
  try {
    const result = await axios({
      headers: {
        Authorization: `Token ${controller.cookies.get("apiToken")}`,
      },
      method: "GET",
      responseType: "arraybuffer",
      url: params.sentFile,
    });

    downloadZipFile(result.data, params.zipFileName);
  } catch (error: unknown) {
    SetErrorMessage(controller, {
      ...params,
      error: (error as AxiosError).response?.data || (error as AxiosError).message,
    });
  }
};
