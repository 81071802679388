import WasmController from "react-lib/frameworks/WasmController";

// APIS
// INF
import moment from "moment";

import EncounterDetail from "issara-sdk/apis/EncounterDetail_core";

import BillTransactionList from "issara-sdk/apis/BillTransactionList_apps_INF";
import BillTransactionSummary from "issara-sdk/apis/BillTransactionSummaryList_apps_INF";
import CommonHisReferenceView from "issara-sdk/apis/CommonHisReferenceView_apps_INF";
import CoveragePayerSentClaimGroupList from "issara-sdk/apis/CoveragePayerSentClaimGroupList_apps_INF";
import DoUpdateSentClaimDataFromARList from "issara-sdk/apis/DoUpdateSentClaimDataFromARList_apps_INF";
import DownloadDBFView from "issara-sdk/apis/DownloadDBFView_apps_INF";
import EClaimADPDetail from "issara-sdk/apis/EClaimADPDetail_apps_INF";
import EClaimADPList from "issara-sdk/apis/EClaimADPList_apps_INF";
import EClaimAERDetail from "issara-sdk/apis/EClaimAERDetail_apps_INF";
import EClaimAERList from "issara-sdk/apis/EClaimAERList_apps_INF";
import EClaimCHADetail from "issara-sdk/apis/EClaimCHADetail_apps_INF";
import EClaimCHAList from "issara-sdk/apis/EClaimCHAList_apps_INF";
import EClaimCHTDetail from "issara-sdk/apis/EClaimCHTDetail_apps_INF";
import EClaimCHTList from "issara-sdk/apis/EClaimCHTList_apps_INF";
import EClaimDRUDetail from "issara-sdk/apis/EClaimDRUDetail_apps_INF";
import EClaimDRUList from "issara-sdk/apis/EClaimDRUList_apps_INF";
import EClaimIDXDetail from "issara-sdk/apis/EClaimIDXDetail_apps_INF";
import EClaimIDXList from "issara-sdk/apis/EClaimIDXList_apps_INF";
import EClaimINSDetail from "issara-sdk/apis/EClaimINSDetail_apps_INF";
import EClaimINSList from "issara-sdk/apis/EClaimINSList_apps_INF";
import EClaimIOPDetail from "issara-sdk/apis/EClaimIOPDetail_apps_INF";
import EClaimIOPList from "issara-sdk/apis/EClaimIOPList_apps_INF";
import EClaimIPDDetail from "issara-sdk/apis/EClaimIPDDetail_apps_INF";
import EClaimIPDList from "issara-sdk/apis/EClaimIPDList_apps_INF";
import EClaimIRFDetail from "issara-sdk/apis/EClaimIRFDetail_apps_INF";
import EClaimIRFList from "issara-sdk/apis/EClaimIRFList_apps_INF";
import EClaimLVDDetail from "issara-sdk/apis/EClaimLVDDetail_apps_INF";
import EClaimLVDList from "issara-sdk/apis/EClaimLVDList_apps_INF";
import EClaimODXDetail from "issara-sdk/apis/EClaimODXDetail_apps_INF";
import EClaimODXList from "issara-sdk/apis/EClaimODXList_apps_INF";
import EClaimOOPDetail from "issara-sdk/apis/EClaimOOPDetail_apps_INF";
import EClaimOOPList from "issara-sdk/apis/EClaimOOPList_apps_INF";
import EClaimOPDDetail from "issara-sdk/apis/EClaimOPDDetail_apps_INF";
import EClaimOPDList from "issara-sdk/apis/EClaimOPDList_apps_INF";
import EClaimORFDetail from "issara-sdk/apis/EClaimORFDetail_apps_INF";
import EClaimORFList from "issara-sdk/apis/EClaimORFList_apps_INF";
import EClaimPATDetail from "issara-sdk/apis/EClaimPATDetail_apps_INF";
import EClaimPATList from "issara-sdk/apis/EClaimPATList_apps_INF";
import HISReferenceListForART from "issara-sdk/apis/HISReferenceListForART_apps_INF";
import HISReferenceSummaryForART from "issara-sdk/apis/HISReferenceSummaryForART_apps_INF";
import HISReferenceUpdateRefNoView from "issara-sdk/apis/HISReferenceUpdateRefNoView_apps_INF";
import SendClaimViaEClaimReport from "issara-sdk/apis/SendClaimViaEClaimReport_apps_INF";
import UpdateRefNoView from "issara-sdk/apis/UpdateRefNoView_apps_INF";

// Serializer
import BillTransactionListSerializer from "issara-sdk/types/BillTransactionListSerializer_apps_INF";

// Common
import { SetErrorMessage, SetProperty } from "react-lib/apps/HISV3/common/CommonInterface";

import { State as MainState } from "HIS/MainHISInterface";

// Utils
import { formatDate } from "react-lib/utils/dateUtils";
import { downloadFile } from "react-lib/utils/utils";

export type State = Partial<{
  // sequence
  ExportEClaimFileSequence: Partial<{
    sequenceIndex: "ACTION" | "START" | null;
    billTransactionList: BillTransactionListSerializer[];
    billTransactionSummary: {
      total_other_pay_price: string;
      total_paid_price: string;
      total_rows: number;
      total_sent_claim_price: string;
    } | null;
    claimInfoDetail: Partial<ClaimInfoDetailType> | null;
    filter: Partial<FilterType>;
    coveragePayerOptions: any[];
  }> | null;
}>;

type PickedState = Partial<
  Pick<MainState, "buttonLoadCheck" | "errorMessage" | "masterOptions" | "userTokenize">
>;

export type PickedProps = Partial<Omit<PickedState, "masterOptions">>;

export type FilterType = {
  arTransaction: number | null;
  arTransactionData: Record<string, any> | null;
  coveragePayer: number | null;
  encounter: number;
  endDate: string;
  hn: string;
  isEncounter: boolean;
  isHN: boolean;
  isSpecifyDate: boolean;
  isStatus: boolean;
  notSpecifyRefNo: boolean;
  patientId: number;
  startDate: string;
  status: string;
  treatmentType: string;
};

export type ClaimInfoDetailType = Record<string, any> & {
  id: number;
  files: Partial<Record<Key16Files, any[]>>;
  his_reference: number | null;
  patient_name: string;
  ref_no: string | null;
};

export type Key16Files = keyof typeof TABLE_16_FILES;

export type MasterOptionsType = Record<(typeof Masters)[number][0], OptionType[]>;

type OptionType = {
  key: number | string;
  text: string;
  value: number | string;
};

// Sequence
type SeqState = {
  sequence: "ExportEClaimFile";
  clear?: boolean;
  restart?: boolean;
};

// Handle Action
type ActionType =
  | {
      action: "CHANGE_VALUE";
      id: string;
      // text: string;
      index: number;
      type: Key16Files;
      value: number;
    }
  | { action: "DESCRIPTION"; data: Record<string, any> }
  | { action: "EDIT_REF_NO"; card: string; errorKey: string; refNo: string }
  | { action: "EXPORT_DATA_16_FILE"; card: string }
  | { action: "PRINT_DOCUMENT"; card: string }
  | {
      action: "SAVE_ECLAIM";
      card: string;
      data: Record<string, any>;
      errorKey: string;
      type: Key16Files;
      onSuccess?: () => any;
    }
  | { action: "SAVE_REF_NO"; card: string; refNo: string; onSuccess?: () => any }
  | { action: "SEARCH"; card: string }
  | { action: "SELECT_TYPE"; type: Key16Files }
  | { action: "UPDATE_ECLAIM" };

type SeqAct = ActionType & SeqState;
type SeqType<K> = K extends { action: string } ? Extract<SeqAct, K> : SeqState;

export type RunSequence = <K extends keyof SeqAct>(params: SeqType<Pick<SeqAct, K>>) => any;

export type SetProp = SetProperty<PickedState & State>;

type CustomExtract<T, U> = T extends object ? (U extends Partial<T> ? T : never) : never;

type Params<A extends ActionType["action"]> = CustomExtract<ActionType, { action: A }>;

export const StateInitial: State = {
  // sequence
  ExportEClaimFileSequence: {
    sequenceIndex: null,
  },
};

export type Event =
  | { message: "GetMasterData"; params: Record<string, unknown> }
  | { message: "RunSequence"; params: Record<string, unknown> };

export type Data = {
  division?: number;
};

export const DataInitial = {};

const Masters = [
  ["sentClaimChoice", {}],
  ["province", {}],
  ["district", {}],
  ["icd9cm", {}],
  ["icd10", {}],
  ["patientCondition", {}],
  ["patientDischarge", {}],
  ["career", {}],
  ["nationality", {}],
] as const;

export const CARD_EXPORT_E_CLAIM_FILE = "CardExportEClaimFile";

type Optional<T> = {
  [K in keyof T]: `${typeof CARD_EXPORT_E_CLAIM_FILE}_${T[K] & string}`;
};

export const ACTIONS = {
  CHANGE_TAB: "CHANGE_TAB",
  CHANGE_VALUE: "CHANGE_VALUE",
  DESCRIPTION: "DESCRIPTION",
  EDIT_REF_NO: "EDIT_REF_NO",
  EXPORT_DATA_16_FILE: "EXPORT_DATA_16_FILE",
  PRINT_DOCUMENT: "PRINT_DOCUMENT",
  SAVE_ECLAIM: "SAVE_ECLAIM",
  SAVE_REF_NO: "SAVE_REF_NO",
  SEARCH: "SEARCH",
  SELECT_TYPE: "SELECT_TYPE",
  UPDATE_ECLAIM: "UPDATE_ECLAIM",
} as const;

export const BTN_ACTS = Object.fromEntries(
  Object.keys(ACTIONS).map((key) => [key, `${CARD_EXPORT_E_CLAIM_FILE}_${key}`])
) as Optional<typeof ACTIONS>;

export const GENDER_OPTIONS = [
  { key: "M", text: "ชาย", value: "1" },
  { key: "F", text: "หญิง", value: "2" },
];

export const DISEASE_TYPE_OPTIONS = [
  { key: 1, text: "Primary Diagnosis", value: "1" },
  { key: 2, text: "โรคร่วม (Co morbidity)", value: "2" },
  { key: 3, text: "โรคแทรกซ้อน (Complication)", value: "3" },
  { key: 4, text: "อื่นๆ (Others)", value: "4" },
  { key: 5, text: "สาเหตุภายนอก (External Cause)", value: "5" },
];

export const MARRIAGE_OPTIONS = [
  { key: 1, text: "โสด", value: "1" },
  { key: 2, text: "สมรส", value: "2" },
  { key: 3, text: "หม้าย", value: "3" },
  { key: 4, text: "หย่า", value: "4" },
  { key: 5, text: "แยกกันอยู่", value: "5" },
  { key: 6, text: "สมณะ", value: "6" },
  { key: 9, text: "ไม่ทราบ", value: "9" },
];

export const REFER_TYPE_OPTIONS = [
  { key: 1, text: "รับเข้า", value: "1" },
  { key: 2, text: "ส่งออก ", value: "2" },
];

export const PROCEDURE_OPTIONS = [
  { key: 1, text: "Principal procedure", value: "1" },
  { key: 2, text: "Secondary procedure", value: "2" },
  { key: 3, text: "Others", value: "3" },
];

export const AE_TYPE_OPTIONS = [
  { key: 1, text: "[V] ใช้ พรบ. ผู้ประสบภัยจากรถ", value: "V" },
  { key: 2, text: "[O] ใช้พรบ. กองทุนเงินทดแทน", value: "O" },
  { key: 3, text: "[B] ใช้ทั้ง พรบ. ผู้ประสบภัย และ พรบ. กองทุนเงินทดแทน", value: "B" },
];

export const UCAE_TYPE_OPTIONS = [
  { key: 1, text: "[A] อุบัติเหตุ (Accident/Accident + Emergency)", value: "A" },
  { key: 2, text: "[E] ฉุกเฉิน (Emergency)", value: "E" },
  { key: 3, text: "[I] OP Refer ในจังหวัด", value: "I" },
  { key: 4, text: "[O] OP Refer ข้ามจังหวัด", value: "O" },
  { key: 5, text: "[C] ย้ายหน่วยบริการเกิดสิทธิทันที", value: "C" },
  { key: 6, text: "[Z] บริการเชิงรุก", value: "Z" },
];

export const EM_TYPE_OPTIONS = [
  { key: 1, text: "ต้องการรักษาเป็นการด่วน", value: "1" },
  { key: 2, text: "ต้องการผ่าตัดด่วน", value: "2" },
  { key: 3, text: "โรคที่คณะกรรมการกำหนด", value: "3" },
];

export const UUC_TYPE_OPTIONS = [
  { key: 1, text: "ใช้สิทธิ", value: "1" },
  { key: 2, text: "ไม่ใช้สิทธิ", value: "2" },
];

export const USE_STATUS_OPTIONS = [
  { key: 1, text: "ใช้ในโรงพยาบาล", value: "1" },
  { key: 2, text: "ใช้ที่บ้าน", value: "2" },
];

export const DRUG_REMARK_OPTIONS = [
  {
    key: 1,
    text: "EA : เกิดอาการไม่พึงประสงค์จากยาหรือแพ้ยาที่สามารถใช้ได้ในบัญชียาหลักแห่งชาติ",
    value: "EA",
  },
  {
    key: 2,
    text: "EB : ผลการรักษาไม่บรรลุเป้าหมายแม้ว่าได้ใช้ยาในบัญชียาหลักแห่งชาติครบตามมาตรฐานการรักษาแล้ว",
    value: "EB",
  },
  {
    key: 3,
    text: "EC : ไม่มีกลุ่มยาในบัญชียาหลักแห่งชาติให้ใช้ แต่ผู้ป่วยมีความจาเป็นในการใช้ยานี้ ตามข้อบ่งชี้ที่ได้ขึ้นทะเบียนไว้กับสานักงานคณะกรรมการอาหารและยา",
    value: "EC",
  },
  {
    key: 4,
    text: "ED : ผู้ป่วยมีภาวะหรือโรคที่ห้ามใช้ยาในบัญชีอย่างสมบูรณ์ หรือมีข้อห้ามการใช้ยาในบัญชีร่วมกับยาอื่น ที่ผู้ป่วยจาเป็นต้องใช้อย่างหลักเลี่ยงไม่ได้",
    value: "ED",
  },
  {
    key: 5,
    text: "EE : ยาในบัญชียาหลักแห่งชาติมีราคาแพงกว่า (ในเชิงความคุ้มค่า)",
    value: "EE",
  },
  {
    key: 6,
    text: "EF : ผู้ป่วยแสดงความจานงต้องการ (เบิกไม่ได้)",
    value: "EF",
  },
  {
    key: 7,
    text: "PA : ยากลุ่มที่ต้องขออนุมัติก่อนการใช้ (PA) เช่น ยามะเร็ง 6 ชนิด ยารักษากลุ่มโรครูมาติกและโรคสะเก็ดเงิน 2 ชนิด",
    value: "PA",
  },
];

const INVOICE_API = {
  BILL_TRANSACTION: {
    list: BillTransactionList.list,
    summary: BillTransactionSummary.get,
  },
  E_CLAIM: {
    list: HISReferenceListForART.list,
    summary: HISReferenceSummaryForART.list,
  },
};

type Handler<P = unknown, R = void> = (
  controller: WasmController<PickedState & State, Event, Data>,
  ...params: unknown extends P ? [params?: P] : [params: P]
) => R;

/* ------------------------------------------------------ */

/*                          START                         */

/* ------------------------------------------------------ */
export const GetMaster: Handler<SeqState> = async (controller) => {
  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: Masters,
    },
  } as any);

  const coveragePayerResult = await CoveragePayerSentClaimGroupList.list({
    apiToken: controller.apiToken,
    params: {},
  });

  const coveragePayerOptions = [...(coveragePayerResult?.[0]?.items || [])].map((item: any) => ({
    claim_type: item.claim_type,
    group_type: item.group_type,
    key: item.id,
    text: `[${item.code}] ${item.name}`,
    value: item.id,
  }));

  controller.setState(
    {
      ExportEClaimFileSequence: {
        sequenceIndex: "ACTION",
        coveragePayerOptions,
        filter: {
          coveragePayer: coveragePayerOptions[0]?.value,
        },
      },
    }
    // () => Action(controller, { action: "DESCRIPTION", data: MOCK_DATA })
  );
};

/* ------------------------------------------------------ */

/*                      Handle Action                     */

/* ------------------------------------------------------ */
export const Action: Handler<ActionType> = async (controller, params) => {
  const actionHandlers: Partial<{ [K in ActionType["action"]]: Handler<Params<K>> }> = {
    [ACTIONS.CHANGE_VALUE]: HandleChangeValue,
    [ACTIONS.DESCRIPTION]: HandleDescription,
    [ACTIONS.EDIT_REF_NO]: HandleEditRefNo,
    [ACTIONS.EXPORT_DATA_16_FILE]: HandleExportData16File,
    [ACTIONS.PRINT_DOCUMENT]: HandlePrintDocument,
    [ACTIONS.SAVE_ECLAIM]: HandleSaveEClaim,
    [ACTIONS.SAVE_REF_NO]: HandleSaveRefNo,
    [ACTIONS.SEARCH]: HandleSearch,
    [ACTIONS.SELECT_TYPE]: HandleSelectType,
    [ACTIONS.UPDATE_ECLAIM]: HandleUpdateEClaim,
  };

  const { action } = params;

  return actionHandlers[action]?.(controller, params as Params<typeof params.action>);
};

/* ------------------------------------------------------ */

/*                         Action                         */

/* ------------------------------------------------------ */
const HandleSearch: Handler = async (controller) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "LOADING" },
  });

  const coveragePayerOptions = state.ExportEClaimFileSequence?.coveragePayerOptions || [];
  const urlParams = getUrlParams(controller);

  const coveragePayer =
    coveragePayerOptions.find(
      (option) => option.value === urlParams.coverage_payer_sent_claim_group
    ) || {};

  const groupType = coveragePayer.group_type;
  const eclaimType = coveragePayer.claim_type;
  const eclaimGroupType =
    eclaimType === "E_CLAIM" && groupType === "IPD" ? "E_CLAIM" : "BILL_TRANSACTION";

  const api = INVOICE_API[eclaimGroupType];

  const [[list], [summary]] = await Promise.all([
    api.list({
      apiToken: controller.apiToken,
      params: urlParams,
    }),
    api.summary({
      apiToken: controller.apiToken,
      params: urlParams,
    }),
  ]);

  controller.setState({
    ExportEClaimFileSequence: {
      ...state.ExportEClaimFileSequence,
      billTransactionList: list?.items || [],
      billTransactionSummary: summary?.items || summary || {},
      // billTransactionSummary: {
      //   total_rows: 4,
      //   total_sent_claim_price: 113606,
      //   total_other_pay_price: 0,
      //   total_paid_price: 208720,
      // },
    },
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SEARCH]: "SUCCESS" },
  });
};

export const HandleExportData16File: Handler<Params<"EXPORT_DATA_16_FILE">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.EXPORT_DATA_16_FILE]: "LOADING" },
  });

  const arTransaction = state.ExportEClaimFileSequence?.filter?.arTransaction;

  const [, error, net] = await DownloadDBFView.get({
    apiToken: controller.apiToken,
    params: { ar_transaction: arTransaction },
    extra: { responseType: "blob" },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });

    return;
  }

  downloadFile(net);

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.EXPORT_DATA_16_FILE]: "SUCCESS" },
  });

  HandleSearch(controller);
};

const HandleSaveRefNo: Handler<Params<"SAVE_REF_NO">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE_REF_NO]: "LOADING" },
  });

  const result = await UpdateRefNoView.put({
    apiToken: controller.apiToken,
    data: {
      ar_transaction: state.ExportEClaimFileSequence?.filter?.arTransaction, // # ar transaction id(invoice group)
      ref_no: params.refNo, // # reference number (must be numeric)
    },
  });

  if (result[1]) {
    SetErrorMessage(controller, { ...params, error: result[1] });
  } else {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE_REF_NO]: "SUCCESS" },
    });

    params.onSuccess?.();
  }

  HandleSearch(controller);
};

const HandleEditRefNo: Handler<Params<"EDIT_REF_NO">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.EDIT_REF_NO]: "LOADING" },
  });

  const claim = state.ExportEClaimFileSequence;
  const detail = claim?.claimInfoDetail;

  const bill = await HISReferenceUpdateRefNoView.put({
    apiToken: controller.apiToken,
    data: {
      // id: detail?.id,
      ref_no: params.refNo,
    },
    pk: detail?.his_reference,
  });

  if (bill[1]) {
    SetErrorMessage(controller, { ...params, error: bill[1] });
  } else {
    controller.setState({
      ExportEClaimFileSequence: {
        ...claim,
        billTransactionList: (claim?.billTransactionList || []).map((item) =>
          item.id === detail?.id ? { ...item, ref_no: bill[0].ref_no } : item
        ),
      },
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.EDIT_REF_NO]: "SUCCESS" },
    });
  }
};

const HandleDescription: Handler<Params<"DESCRIPTION">> = async (controller, params) => {
  const state = controller.getState();

  const hisReference = params.data.his_reference || params.data.id;

  const [result] = await CommonHisReferenceView.get({
    apiToken: controller.apiToken,
    his_ref_id: hisReference,
  });

  controller.setState({
    ExportEClaimFileSequence: {
      ...state.ExportEClaimFileSequence,
      claimInfoDetail: {
        ...result,
        id: params.data.id,
        files: {
          adp: [],
          aer: [],
          cha: [],
          cht: [],
          dru: [],
          idx: [],
          ins: [],
          iop: [],
          ipd: [],
          irf: [],
          lvd: [],
          odx: [],
          oop: [],
          opd: [],
          orf: [],
          pat: [],
        },
        his_reference: params.data.his_reference,
        patient_name: params.data.name,
        ref_no: params.data.ref_no,
      },
    },
  });
};

const HandleChangeValue: Handler<Params<"CHANGE_VALUE">> = (controller, params) => {
  const state = controller.getState();

  const claim = state.ExportEClaimFileSequence;
  const { id, index, type, value } = params;

  if (!claim?.claimInfoDetail?.files?.[type]?.[index]) {
    return;
  }

  if (id === "changwat") {
    claim.claimInfoDetail.files[type][index].amphur = null;
  }

  claim.claimInfoDetail.files[type][index][id] = value;

  controller.setState({
    ExportEClaimFileSequence: {
      ...claim,
      claimInfoDetail: {
        ...claim.claimInfoDetail,
      },
    },
  });
};

const HandleSelectType: Handler<Params<"SELECT_TYPE">> = async (controller, params) => {
  const state = controller.getState();

  const claim = state.ExportEClaimFileSequence?.claimInfoDetail || {};
  const hisReference = claim.his_reference || claim.id;

  const result = await TABLE_16_FILES[params.type].list({
    apiToken: controller.apiToken,
    params: {
      his_reference: hisReference,
    },
  });

  controller.setState({
    ExportEClaimFileSequence: {
      ...state.ExportEClaimFileSequence,
      claimInfoDetail: {
        ...claim,
        files: {
          ...claim.files,
          [params.type]: result?.[0]?.items || [],
        },
      },
    },
  });
};

const HandleUpdateEClaim: Handler<Params<"UPDATE_ECLAIM">> = async (controller, params) => {
  const state = controller.getState();
  const filter = state.ExportEClaimFileSequence?.filter || {};

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.UPDATE_ECLAIM]: "LOADING" },
  });

  const result = await DoUpdateSentClaimDataFromARList.get({
    apiToken: controller.apiToken,
    ar_transaction_id: filter.arTransaction,
  });

  if (result[1]) {
    SetErrorMessage(controller, { ...params, error: result[1] });
  }
};

const HandleSaveEClaim: Handler<Params<"SAVE_ECLAIM">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE_ECLAIM]: "LOADING" },
  });

  const result = await TABLE_16_FILES[params.type].update({
    apiToken: controller.apiToken,
    data: {
      ...params.data,
      user: state.userTokenize?.employeeName,
    } as any,
    pk: params.data.id,
  });

  if (result[1]) {
    SetErrorMessage(controller, { ...params, error: result[1] });
  } else {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.SAVE_ECLAIM]: "SUCCESS" },
    });

    params.onSuccess?.();
  }
};

const HandlePrintDocument: Handler<Params<"PRINT_DOCUMENT">> = async (controller, params) => {
  const state = controller.getState();

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.PRINT_DOCUMENT]: "LOADING" },
  });

  const [, error, net] = await SendClaimViaEClaimReport.get({
    apiToken: controller.apiToken,
    params: getUrlParams(controller),
    extra: { responseType: "blob" },
  });

  if (error) {
    SetErrorMessage(controller, { ...params, error });

    return;
  }

  downloadFile(net);

  controller.setState({
    buttonLoadCheck: { ...state.buttonLoadCheck, [BTN_ACTS.PRINT_DOCUMENT]: "SUCCESS" },
  });
};

const getUrlParams: Handler<unknown, Record<string, any>> = (controller) => {
  const state = controller.getState();
  const filter = state.ExportEClaimFileSequence?.filter || {};

  const formatParams = (isKey: keyof FilterType, value?: FilterType[keyof FilterType]) =>
    filter[isKey] ? value : undefined;

  return {
    ar_transaction: filter.arTransaction,
    coverage_payer_sent_claim_group: filter.coveragePayer,
    encounter: formatParams("isEncounter", filter.encounter),
    end_date: formatParams("isSpecifyDate", filter.endDate),
    patient: formatParams("isHN", filter.patientId),
    start_date: formatParams("isSpecifyDate", filter.startDate),
    status: formatParams("isStatus", filter.status),
  };
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
// 123.456789 > 123.456
export const sanitizeNumber = (value: string, digitLimit = 3): string => {
  const digitLimitRegex = new RegExp(`(\\.\\d{${digitLimit}})\\d+$`);

  return value
    .replaceAll(/[^\d+.]/g, "") // Remove all characters except digits, dot, and plus sign
    .replace(digitLimitRegex, "$1"); // Remove digits after the specified decimal place limit
};

/* ------------------------------------------------------ */

/*                        Template                        */

/* ------------------------------------------------------ */
export const TABLE_16_FILES = {
  adp: {
    headers: [
      "HN",
      "AN",
      "DATEOPD",
      "TYPE",
      "CODE",
      "QTY",
      "RATE",
      "SEQ",
      "CAGCODE",
      "DOSE",
      "CA_TYPE",
      "SERIALNO",
      "TOTCOPAY",
      "USE_STATUS",
      "TOTAL",
      "QTYDAY",
    ],
    list: EClaimADPList.list,
    required: [],
    title: "ข้อมูลค่าใช้จ่ายเพิ่ม และบริการที่ยังไม่ได้จัดหมวด",
    update: EClaimADPDetail.update,
    widths: "^70,^120,^75,^60,^105,^65,^110,^125,^95,^90,^60,^195,^115,^80,^95,^75",
  },
  aer: {
    headers: [
      "HN",
      "AN",
      "DATEOPD",
      "AUTHAE",
      "AEDATE",
      "AETIME",
      "AETYPE",
      "REFER_NO",
      "REFMAINI",
      "IREFTYPE",
      "REFMAINO",
      "OREFTYPE",
      "UCAE",
      "EMTYPE",
      "SEQ",
    ],
    list: EClaimAERList.list,
    required: [
      "AUTHAE",
      "AETIME",
      "AETYPE",
      "REFER_NO",
      "REFMAINI",
      "IREFTYPE",
      "REFMAINO",
      "OREFTYPE",
      "UCAE",
      "EMTYPE",
    ],
    title: "ข้อมูลอุบัติเหตุ ฉุกเฉิน และรับส่งเพื่อรักษา",
    update: EClaimAERDetail.update,
    widths: "^70,^70,^70,^70,^100,^100,^150,^160,^120,^120,^120,^120,^150,^150,^70,100",
  },
  cha: {
    headers: ["HN", "AN", "DATE", "CHRGITEM", "AMOUNT", "PERSON_ID", "SEQ"],
    list: EClaimCHAList.list,
    required: [],
    title: "ข้อมูลการเงิน (แบบรายละเอียด)",
    update: EClaimCHADetail.update,
    widths: "^60,,,,,,,100",
  },
  cht: {
    headers: ["HN", "AN", "DATE", "TOTAL", "PAID", "PTTYPE", "PERSON_ID", "SEQ"],
    list: EClaimCHTList.list,
    required: [],
    title: "ข้อมูลการเงิน (แบบสรุป)",
    update: EClaimCHTDetail.update,
    widths: ",,,,,,,,100",
  },
  dru: {
    headers: [
      "HCODE",
      "HN",
      "AN",
      "CLINIC",
      "PERSON_ID",
      "DATE_SERV",
      "DID",
      "DIDNAME",
      "AMOUNT",
      "DRUGPRIC",
      "DRUGCOST",
      "DIDSTD",
      "UNIT",
      "UNIT_PACK",
      "SEQ",
      "DRUGREMARK",
      "PA_NO",
      "TOTCOPAY",
      "USE_STATUS",
      "TOTAL",
    ],
    list: EClaimDRUList.list,
    required: [
      "CLINIC",
      "DATE_SERV",
      "DID",
      "DIDNAME",
      "AMOUNT",
      "DRUGPRIC",
      "DRUGCOST",
      "DIDSTD",
      "UNIT",
      "UNIT_PACK",
      "DRUGREMARK",
      "PA_NO",
      "TOTCOPAY",
      "USE_STATUS",
    ],
    title: "ข้อมูลการใช้ยา",
    update: EClaimDRUDetail.update,
    widths:
      "^60,^80,^120,^75,^115,^135,^200,^200,^140,^150,^150,^220,^120,^120,^120,^175,^105,^135,^110,^100,100",
  },
  idx: {
    headers: ["AN", "DIAG", "DXTYPE", "DRDX"],
    list: EClaimIDXList.list,
    required: ["DIAG", "DXTYPE", "DRDX"],
    title: "ข้อมูลวินิจฉัยโรคผู้ป่วยใน",
    update: EClaimIDXDetail.update,
    widths: "^80,^200,^110,^80,100",
  },
  ins: {
    headers: [
      "HN",
      "INSCL",
      "SUBTYPE",
      "CID",
      "DATEIN",
      "DATEEXP",
      "HOSPMAIN",
      "HOSPSUB",
      "GOVCODE",
      "GOVNAME",
      "PERMITNO",
      "DOCNO",
      "OWNRPID",
      "OWNNAME",
      "AN",
      "SEQ",
      "SUBINSCL",
      "RELINSCL",
    ],
    // APIS
    list: EClaimINSList.list,
    required: ["HOSPMAIN", "HOSPSUB", "GOVCODE", "GOVNAME", "PERMITNO", "DOCNO"],
    title: "ข้อมูลผู้มีสิทธิการรักษาพยาบาล",
    update: EClaimINSDetail.update,
    widths: "80,45,60,^115,75,75,^90,^90,^115,^115,^105,^105,^100,^80,^75,^60,^60,^70,100",
  },
  iop: {
    headers: ["AN", "OPER", "OPTYPE", "DROPID", "DATEIN", "TIMEIN", "DATEOUT", "TIMEOUT"],
    list: EClaimIOPList.list,
    required: ["OPER", "OPTYPE", "DROPID", "DATEIN", "TIMEIN", "DATEOUT", "TIMEOUT"],
    title: "ข้อมูลวินิจฉัยโรคผู้ป่วยใน",
    update: EClaimIOPDetail.update,
    widths: ",,,,,,,,100",
  },
  ipd: {
    headers: [
      "HN",
      "AN",
      "DATEADM",
      "TIMEADM",
      "DATEDSC",
      "TIMEDSC",
      "DISCHS",
      "DISCHT",
      "WARDDSC",
      "DEPT",
      "ADM_W",
      "UUC",
      "SVCTYPE",
    ],
    // APIS
    list: EClaimIPDList.list,
    required: ["DATEDSC", "TIMEDSC", "ADM_W"],
    title: "ข้อมูลผู้ป่วยใน",
    update: EClaimIPDDetail.update,
    widths: ",70,,70,140,,,,,,100,,,100",
  },
  irf: {
    headers: ["AN", "REFER", "REFERTYPE"],
    list: EClaimIRFList.list,
    required: ["REFER", "REFERTYPE"],
    title: "ข้อมูลผู้ป่วยในที่ต้องส่งต่อ",
    update: EClaimIRFDetail.update,
    widths: ",,,100",
  },
  lvd: {
    headers: ["SEQLVD", "AN", "DATEOUT", "TIMEOUT", "DATEIN", "TIMEIN", "QTYDAY"],
    list: EClaimLVDList.list,
    required: [],
    title: "ข้อมูลกรณีที่ผู้ป่วยมีการลากลับบ้าน (Leave day)",
    update: EClaimLVDDetail.update,
    widths: ",,,,,,,100",
  },
  odx: {
    headers: ["HN", "DATEDX", "CLINIC", "DIAG", "DXTYPE", "DRDX", "PERSION_ID", "SEQ"],
    // APIS
    list: EClaimODXList.list,
    required: ["DATEDX", "CLINIC", "DIAG", "DXTYPE", "DRDX"],
    title: "ข้อมูลวินิจฉัยโรคผู้ป่วยนอก",
    update: EClaimODXDetail.update,
    widths: "^80,^80,^80,^150,^130,^80,^80,^80,100",
  },
  oop: {
    headers: ["HN", "DATEOPD", "CLINIC", "OPER", "DROPID", "PERSON_ID", "SEQ"],
    // APIS
    list: EClaimOOPList.list,
    required: ["OPER", "DROPID"],
    title: "ข้อมูลหัตถการผู้ป่วยนอก",
    update: EClaimOOPDetail.update,
    widths: ",,,,,,,100",
  },
  opd: {
    headers: ["HN", "CLINIC", "DATEOPD", "TIMEOPD", "SEQ", "UUC"],
    list: EClaimOPDList.list,
    required: ["CLINIC", "DATEOPD", "TIMEOPD", "UUC"],
    title: "ข้อมูลการมารับบริการผู้ป่วยนอก",
    update: EClaimOPDDetail.update,
    widths: ",,,,,,100",
  },
  orf: {
    headers: ["HN", "DATEOPD", "CLINIC", "REFER", "REFERTYPE", "SEQ"],
    list: EClaimORFList.list,
    required: ["REFER", "REFERTYPE"],
    title: "ข้อมูลผู้ป่วยนอกส่งต่อ",
    update: EClaimORFDetail.update,
    widths: "^80,^80,^80,^120,^80,^80,100",
  },
  pat: {
    headers: [
      "HCODE",
      "HN",
      "CHANGWAT",
      "AMPHUR",
      "DOB",
      "SEX",
      "MARRIAGE",
      "OCCUPA",
      "NATION",
      "PERSON_ID",
      "NAMEPAT",
      "TITLE",
      "FNAME",
      "LNAME",
      "IDTYPE",
    ],
    // APIS
    list: EClaimPATList.list,
    required: ["CHANGWAT", "AMPHUR", "DOB", "SEX", "MARRIAGE", "OCCUPA", "NATION"],
    title: "ข้อมูลผู้ป่วยกลาง",
    update: EClaimPATDetail.update,
    widths: "70,85,140,140,140,110,100,^140,^110,125,^120,^80,^100,^100,^60,100",
  },
} as const;

export const HEADERS = {
  adp: [
    "HN",
    "AN",
    "DATEOPD",
    "TYPE",
    "CODE",
    "QTY",
    "RATE",
    "SEQ",
    "CAGCODE",
    "DOSE",
    "CA_TYPE",
    "SERIALNO",
    "TOTCOPAY",
    "USE_STATUS",
    "TOTAL",
    "QTYDAY",
  ],
  aer: [
    "HN",
    "AN",
    "DATEOPD",
    "AUTHAE",
    "AEDATE",
    "AETIME",
    "AETYPE",
    "REFER_NO",
    "REFMAINI",
    "IREFTYPE",
    "REFMAINO",
    "OREFTYPE",
    "UCAE",
    "EMTYPE",
    "SEQ",
  ],
  cha: ["HN", "AN", "DATE", "CHRGITEM", "AMOUNT", "PERSON_ID", "SEQ"],
  cht: ["HN", "AN", "DATE", "TOTAL", "PAID", "PTTYPE", "PERSON_ID", "SEQ"],
  dru: ["HCODE", "HN", "AN", "CLINIC", "PERSON_ID", "DATE_SERV", "DID", "DIDNAME"],
  idx: ["AN", "DIAG", "DXTYPE", "DRDX"],
  ins: [
    "HN",
    "INSCL",
    "SUBTYPE",
    "CID",
    "DATEIN",
    "DATEEXP",
    "HOSPMAIN",
    "HOSPSUB",
    "GOVCODE",
    "GOVNAME",
  ],
  iop: ["AN", "OPER", "OPTYPE", "DROPID", "DATEIN", "TIMEIN", "DATEOUT", "TIMEOUT"],
  ipd: [
    "HN",
    "AN",
    "DATEADM",
    "TIMEADM",
    "DATEDSC",
    "TIMEDSC",
    "DISCHS",
    "DISCHT",
    "WARDDSC",
    "DEPT",
    "ADM_W",
    "UUC",
  ],
  irf: ["AN", "REFER", "REFERTYPE"],
  lvd: ["SEQLVD", "AN", "DATEOUT", "TIMEOUT", "DATEIN", "TIMEIN", "QTYDAY"],
  odx: ["HN", "DATEDX", "CLINIC", "DIAG", "DXTYPE", "DRDX", "PERSON_ID", "SEQ"],
  oop: ["HN", "DATEOPD", "CLINIC", "OPER", "DROPID", "PERSON_ID", "SEQ"],
  opd: ["HN", "CLINIC", "DATEOPD", "TIMEOPD", "SEQ", "UUC"],
  orf: ["HN", "DATEOPD", "CLINIC", "REFER", "REFERTYPE", "SEQ"],
  pat: [
    "HCODE",
    "HN",
    "CHANGWAT",
    "AMPHUR",
    "DOB",
    "SEX",
    "MARRIAGE",
    "OCCUPA",
    "NATION",
    "PERSON_ID",
    "NAMEPAT",
    "TITLE",
    "FNAME",
    "LNAME",
    "IDTYPE",
  ],
};

// #export const DATA = {
//   adp: [
//     [
//       "55-254",
//       "",
//       "10/10/2022",
//       "12",
//       "64102",
//       "4",
//       "350",
//       "1",
//       "",
//       "",
//       "",
//       "",
//       "0.00",
//       "1",
//       "1400.00",
//       "0",
//     ],
//     [
//       "55-254",
//       "",
//       "10/10/2022",
//       "8",
//       "55020",
//       "1",
//       "50",
//       "1",
//       "",
//       "",
//       "",
//       "",
//       "0.00",
//       "1",
//       "50.00",
//       "0",
//     ],
//   ],
//   aer: [
//     ["55-254", "", "10/10/2022", "", "", "", "", "", "", "0000", "", "0000", "", "", "1"],
//     ["47-759", "", "10/10/2022", "DJJ78X", "", "", "", "", "", "0000", "", "0000", "", "", "1"],
//   ],
//   cha: [
//     ["55-254", "", "10/10/2022", "C1", "50.00", "3101203636923", "1"],
//     ["55-254", "", "10/10/2022", "D1", "1400.00", "3101203636923", "1"],
//   ],
//   cht: [
//     ["55-254", "", "10/10/2022", "1450.00", "320.00", "OF", "3101203636923", "1"],
//     ["47-759", "", "10/10/2022", "21.00", "0.00", "OF", "3650100948676", "10"],
//   ],
//   dru: [
//     [
//       "15199",
//       "42-6874",
//       "",
//       "11",
//       "3102001843449",
//       "10/10/2022",
//       "1AMOX500",
//       "AMOXICILLIN capsule 500 MG",
//     ],
//     [
//       "15199",
//       "42-20222",
//       "",
//       "11",
//       "3420600064353",
//       "10/10/2022",
//       "3NAIRR1000",
//       "SODIUM CHLORIDE for irrigation, solution 0.9%, 1,000 ml",
//     ],
//   ],
//   idx: [],
//   ins: [
//     ["41-15649", "OFC", "11", "", "", "", "", "", "20004", "สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน"],
//     ["41-15857", "OFC", "11", "", "", "", "", "", "20316", "จุฬาลงกรณ์มหาวิทยาลัย"],
//   ],
//   iop: [],
//   ipd: [],
//   irf: [],
//   lvd: [],
//   odx: [
//     ["55-254", "10/10/2022", "11", "F4582", "1", "ท.9327", "3101203636923", "1"],
//     ["42-21575", "10/10/2022", "11", "K089", "1", "ท.3682", "3100902754711", "100"],
//   ],
//   oop: [
//     ["55-254", "10/10/2022", "11", "8931", "ท.9327", "3101203636923", "1"],
//     ["47-759", "10/10/2022", "11", "8931", "ท.2261", "3650100948676", "10"],
//   ],
//   opd: [
//     ["55-254", "11", "10/10/2022", "1334", "1", "1"],
//     ["47-759", "11", "10/10/2022", "1026", "10", "1"],
//   ],
//   orf: [],
//   pat: [
//     [
//       "15199",
//       "41-15649",
//       "10",
//       "05",
//       "4/12/1948",
//       "2",
//       "1",
//       "208",
//       "099",
//       "3669700098400",
//       "นงลักษณ์ น้ำแก้ว",
//       "น.ส.",
//       "นงลักษณ์",
//       "น้ำแก้ว",
//       "1",
//     ],
//     [
//       "15199",
//       "41-15857",
//       "",
//       "",
//       "26/3/1962",
//       "2",
//       "1",
//       "201",
//       "099",
//       "3101800945128",
//       "สุวรรณา แซ่เฮ้ง",
//       "น.ส.",
//       "สุวรรณา",
//       "แซ่เฮ้ง",
//       "1",
//     ],
//   ],
// };

// #const MOCK_DATA = {
//   id: 2370,
//   have_failed: true,
//   rectification_detail: "",
//   status_display: "ยังไม่เคยส่งเบิก",
//   valid: false,
//   error_message:
//     "{'bill_no': [ErrorDetail(string='ไม่สามารถระบุน้อยกว่า 9 ตัวอักษร', code='min_length')], 'verify_code': [ErrorDetail(string=\"ไม่สามารถระบุค่าว่าง '' ที่ช่องนี้\", code='blank')]}",
//   created_at: "2023-10-25T00:05:00.877993+07:00",
//   status: 1,
//   sent_claim_patient_type: "OPD",
//   version: 1,
//   active: true,
//   station: "001",
//   auth_code: "",
//   dt_tran: "2023-10-24T09:03:30",
//   hospital_code: "20316",
//   invoice_no: "66CNO-0000000245",
//   bill_no: "018198",
//   hn: "C06000048",
//   member_no: "",
//   amount: "100.00",
//   paid: "50.00",
//   verify_code: "",
//   t_flag: "A",
//   pid: "3421000704875",
//   name: "น.ส. บัวบูชา มงคลรักษา",
//   hospital_main: "20316",
//   pay_plan: "00",
//   claim_amount: "50.00",
//   other_pay_plan: "ZZ",
//   other_pay: "0.00",
//   ref_no: "",
//   created_by: 1,
//   ar_transaction: 10,
//   sent_claim_coverage: 1,
//   sent_claim_payer: 1526,
//   invoice_ref: 4199,
//   receipt_ref: 4131,
//   edc_transaction_ref: null,
//   coverage_payer_sent_claim_group_item_ref: 1,
//   his_reference: 2229,
// };
